import React from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import showdown from "showdown";
import RegistrationBanner from '../components/RegistrationBanner'

const WardSearch = () => {
  const [propertyList, setPropertyList] = useState([]);
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const [notification, setNotification] = useState(null);
  // = = = = = = = = 
  // Hooks

  // Initial Hook: retrieves companies Data
  const fetchPropertyList = async () => {
    await axios.get('/.netlify/functions/getAddressWithWard')
      .then( (response) => {
        const { addressWithWard } = response.data;
        setPropertyList(addressWithWard);
      })
      .catch( error => console.log(error) )
  }

  useEffect( () => fetchPropertyList(), []);


  // = = = = = = = = 
  // EventHandlers

  const handleChange = (e) => {
    e.preventDefault();
    filterData(e.target.value);
  }

  //  Returns company
  const searchData = (address, value) => {
    return (
      address?.toLowerCase()
        .replace("&", "and")
        .replace("-", "")
        .replace(/\s/g, "")
        .replace(",", "")
        .replace("'", "")
        .includes(value
          .toLowerCase()
          .replace("&", "and")
          .replace("-", "")
          .replace(/\s/g, "")
          .replace(",", "")
          .replace("'", "")
        )
    );
  }

  // Filter Data when query updates
  const filterData = (value) => {
    if(propertyList?.length < 1) return;
    if(value.length < 3) {
      setFilteredAddresses([]);
      setNotification(null);
      return;
    }
    
    const filteredAddress = propertyList?.filter(property => searchData(property.address, value));
    setFilteredAddresses(filteredAddress);

    if(filteredAddress?.length < 1) {
      setNotification('The address is not listed')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/wards');
  }


  return (
    <div className={`hero hero--search hero--search-v2`} >
      <div className='container d-flex flex-column justify-content-between'>
      
        <div className='row justify-content-end'>
          <div className='hero__form'>

          <h3>Request<br/>a Vote</h3>
          <p>If you’re a City worker, register your interest in becoming a voter by searching for your workplace or address below:</p>
            <div className="block-footer">
              <form onSubmit={(e) => handleSubmit(e) } action="">
                <label htmlFor="">Where are you registered to vote?</label>
                <div className="input-wrapper">
                  <input onChange={handleChange} type="text" name="" id="" placeholder="Enter the name or address of your workplace or residence" />
                </div>
              </form>
              {
                filteredAddresses?.length > 0 
                
                &&

                <div className="addresses-wrapper">
                  <ul className="addresses-list">
                    {
                      filteredAddresses.map(property => (
                        <li 
                          data-address={property.address} 
                          key={property.address} 
                        >
                          <Link to={`/ward/${property.ward.toLowerCase().replace("(", "").replace(")", "").replace(/\s+/g, '-')}`}>
                            <span data-address={property.address} className="item-address">{property.address}</span> 
                            <span data-address={property.address} className="item-link">Select Address ›</span>
                          </Link>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              }
              {
                notification && filteredAddresses?.length < 1 
                  &&
                <p className={`form__notification ${notification ? 'show' : undefined}`}>{notification}</p>
              }
              <Link className="link-cta" to="/voting-residents">I'm resident</Link>

            </div>
          </div>
          
        </div>
        
      </div>
    </div>
  )
}

const SearchForm = ({handleChange, handleSubmit, filteredAddresses, notification, getElectionStatus, title, description}) => {
  return(
    <div className={`hero hero--search hero--search-v3`}>
      <div className="container d-flex flex-column justify-content-between">
      <div className={ `row justify-content-start`}>
      <div className="hero__form">

          <h3>{ title }</h3>
          <p>{ description }</p>
          <div className="block-footer">
          <form onSubmit={(e) => handleSubmit(e) } action="">
            {
              getElectionStatus() === 'proceeding' ?
                <label htmlFor="">Find your candidates and where to vote</label>
              :
                <label htmlFor="">Where are you registered to vote?</label>
            }
            <div className="input-wrapper">
              <input onChange={handleChange} type="text" name="" id="" placeholder="Enter the name or address of your workplace or residence" />
            </div>
          </form>
          {
            filteredAddresses?.length > 0 
            
            &&

            <div className="addresses-wrapper">
              <ul className="addresses-list">
                {
                  filteredAddresses.map(property => (
                    <li 
                      data-address={property.address} 
                      key={property.address} 
                    >
                      <Link to={`/ward/${property.ward.toLowerCase().replace("(", "").replace(")", "").replace(/\s+/g, '-')}`}>
                        <span data-address={property.address} className="item-address">{property.address}</span> 
                        <span data-address={property.address} className="item-link">Select Address ›</span>
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </div>
          }
          {
            notification && filteredAddresses?.length < 1 
              &&
            <p className={`form__notification ${notification ? 'show' : undefined}`}>{notification}</p>
          }
        </div>
      </div>
      </div>
      </div>
      
    </div>
   
  )
}

export default function HeroSearchTwoCols({ intro, headline, subhead, showVoteRequest, showFindingWard, titleVoteRquest, descriptionVoteRequest, titleFindWard, descriptionFindingWard }) {
  const [propertyList, setPropertyList] = useState([]);
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const [notification, setNotification] = useState(null);
  const [electionsStatus, setElectionsStatus] = useState(null);
  const [activeForm, setActiveForm] = useState(null);

  const converter = new showdown.Converter();
  const htmlIntro = converter.makeHtml(intro);

  // = = = = = = = = 
  // Hooks

  // Initial Hook: retrieves companies Data
  const fetchPropertyList = async () => {
    await axios.get('/.netlify/functions/getAddressWithWard')
      .then( (response) => {
        const { addressWithWard } = response.data;
        setPropertyList(addressWithWard);
      })
      .catch( error => console.log(error) )
  }

  useEffect( () => fetchPropertyList(), []);

  const getElectionStatus = () => {
    const currentdate = new Date(); 
    const date = currentdate.getDate();
    const month = currentdate.getMonth();
    const hours = currentdate.getHours();
    const minutes = currentdate.getMinutes();
    const seconds = currentdate.getSeconds();

    if((date === 24) && (month + 1 === 3) && ( hours < 20)) {
      return 'proceeding';
    } else if((date === 24) && (month + 1 === 3) && (hours >= 20) && (seconds > 0)) {
      return 'finished';
    }

  }


  // = = = = = = = = 
  // EventHandlers


  const handlerActiveForm = (active) => {
    setActiveForm(active)
  }

  const handleChange = (e) => {
    e.preventDefault();
    filterData(e.target.value);
  }

  //  Returns company
  const searchData = (address, value) => {
    return (
      address?.toLowerCase()
        .replace("'", "")
        .replace("&", "and")
        .replace("-", "")
        .replace(/\s/g, "")
        .replace(",", "")
        .includes(value
          .toLowerCase()
          .replace("&", "and")
          .replace("-", "")
          .replace("'", "")
          .replace(/\s/g, "")
          .replace(",", "")
        )
    );
  }

  // Filter Data when query updates
  const filterData = (value) => {
    if(propertyList?.length < 1) return;
    if(value.length < 3) {
      setFilteredAddresses([]);
      setNotification(null);
      return;
    }
    
    const filteredAddress = propertyList?.filter(property => searchData(property.address, value));
    setFilteredAddresses(filteredAddress);

    if(filteredAddress?.length < 1) {
      setNotification('The address is not listed')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/wards');
  }


  return (
    <div className="hero--search-two-cols">
      { subhead || headline ?
      <div className="container mt-5 mb-3">
        <div className="row justify-content-center">
          <div className="col-10 text-center">
          {
            subhead ? <h2 className="hero__subtitle">{ subhead }</h2> : ''
          }
          { headline ? <h1 className="hero__headline">{ headline }</h1> : '' }
          </div>
        
        </div>
      </div>
      : ''}

      <div className="container-fluid">
          <div className={`${showVoteRequest && showFindingWard ? 'cols-form': 'one-col-form'}`}>
            {
              showVoteRequest && (
                <div className={ activeForm === 0 ? 'active': activeForm !== null ? 'inactive' : ''} onClick={() => handlerActiveForm(0) }>
                  <div className="hero hero--search hero--search-v2">
                    <div className="container d-flex flex-column justify-content-between">
                      <div className="row">
                        <div className="hero__form">
                          <h3>{ titleVoteRquest }</h3>
                          <RegistrationBanner type="main" inHome="true" description={descriptionVoteRequest} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            {
              showFindingWard && (
              <div className={ activeForm === 1 ? 'active': activeForm !== null ? 'inactive' : ''} onClick={() => handlerActiveForm(1) }>
                <SearchForm 
                  handleChange={handleChange} 
                  handleSubmit={handleSubmit} 
                  filteredAddresses={filteredAddresses} 
                  notification={notification}
                  getElectionStatus={getElectionStatus}
                  title={titleFindWard}
                  description={descriptionFindingWard}
                />
                
              </div>
              )
            }
            {
              !showFindingWard && !showVoteRequest && (
                <div className="hero hero--search hero--search-v2 pb-4 text-center">
                  <div className="container">
                    <h3>
                    There are no elections currently underway
                    </h3>
                  </div>
                  
                </div>
              )
            }
            
            
          </div>
      </div>        
     </div>  
    
  )
}